.ShowcaseGallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.8rem;

  &__Title {
    font-size: 1.1rem;
    font-weight: $weight-semi;
    margin-bottom: 1.3rem;
    color: $color-font-sub;
    text-align: center;
  }

  &__Images {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  &__Image {
    object-fit: cover;
    border-radius: 0.8rem;
    flex-grow: 1;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }

    img {
      width: 100%;
      height: 100%;
      max-height: 7.5rem;
      object-fit: cover;
      border-radius: 0.8rem;
    }
  }

  @include breakpoint('mobile') {
    margin-top: 0;
  }
}