.Tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 2rem 0;
  padding: 0;
  list-style: none;

  &.leftAlign {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.Tab{
  box-shadow: inset 0 -1px 0 transparent;
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: $color-text-dark;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  padding-right: 5rem;
  margin-bottom: 1.4rem;
  border-bottom: 2px solid $color-border-bis;

  @include breakpoint('mobile') {}
  a{
    display: block;
    padding: 7px 0 7px 0;
  }

  &--selected{
    color: white;

    &:after{
      content: "";
      height: 1px;
      left: 0;
      right: 5rem;
      border-bottom: 2px solid $color-green;
      position: absolute;
      bottom: -2px;
    }
  }
}
