.DropdownRootMenu {
  border: none;
  font-size: 16px;
  background: none;
  cursor: pointer;
  outline: none;
}

.DropdownRootMenu[data-open],
.DropdownRootMenu:hover {
  background: var(--active-unfocused);
}

.DropdownMenu {
  background: #131722;
  border: 1px solid #3D4463;
  border-radius: 4px;
  outline: 0;
  cursor: default;
  z-index: 10;
}

.DropdownMenuItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  width: 100%;
  border: none;
  font-size: 14px;
  text-align: left;
  min-width: 110px;
  margin: 0;
  outline: 0;
  color: $color-font-sub;
  padding: 0.8rem 1.5rem;
  cursor: default;

  &--selected {
    color: $color-green;
  }
}

.DropdownMenuItem:focus {
  color: $color-green;
  background: rgba(24, 201, 157, 0.1);
}

.DropdownMenuItem[data-nested][data-open]:not([data-focus-inside]) {
  background: rgba(24, 201, 157, 0.1);
  color: $color-green;
}

.DropdownMenuItem[data-focus-inside][data-open] {
  background: rgba(24, 201, 157, 0.1);
  color: $color-green;
}
