// Vars
@import "vars";

// Utils
@import "utils/reset";
@import "utils/responsive";
@import "utils/typography";
@import "utils/utils";

// Components
@import "components/Accordion";
@import "components/AiTabs";
@import "components/Icon";
@import "components/Navbar";
@import "components/Step";
@import "components/Button";
@import "components/Loader";
@import "components/forms/Form";
@import "components/forms/SelectInput";
@import "components/forms/ImageInput";
@import "components/Tabs";
@import "components/PageTitleBar";
@import "components/EmptyState";
@import "components/Pagination";
@import "components/Chart";
@import "components/Scrollbars";
@import "components/Modal";
@import "components/tooltip";
@import "components/Toast";
@import "components/AppShell";
@import "components/DropdownMenu";
@import "components/ServiceContainer";
@import "components/ServiceSidebar";
@import "components/ServiceSidebarSub";
@import "components/ItemsList";
@import "components/UserListRowItem";
@import "components/UserAvatar";
@import "components/OrgAvatar";
@import "components/MemberItem";
@import "components/enable-two-fa";
@import "components/forms/code-input";
@import "components/settings-box";
@import "components/CreditSelection";
@import "components/Overlay";
@import "components/Banner";
@import "components/menu-overlay";
@import "components/side-menu";
@import "components/ObjViewer";
@import "components/forms/ReactDatepicker";
@import "components/DrawingCanvas";
@import "components/OrgAndProjectDropdowns";
@import "components/Price";
@import "components/KnowledgeDocument";
@import "components/StepBar";
@import 'components/forms/Checkbox';


@import "components/ai-showcases/ShowcaseChatbot";
@import "components/ai-showcases/ShowcaseTextToImage";
@import "components/ai-showcases/ShowcaseTextToVideo";
@import "components/ai-showcases/ShowcaseImageItem";
@import "components/ai-showcases/ShowcaseImageToVideo";
@import "components/ai-showcases/ShowcaseSettingsPanel";
@import "components/ai-showcases/ShowcaseViton";
@import "components/ai-showcases/ShowcaseGallery";
@import "components/ai-showcases/ShowcaseSketch";
@import "components/ai-showcases/ShowcaseImageToImageItem";
@import "components/ai-showcases/ShowcaseImageToImage";

// Pages start

// Marketing
@import "pages/marketing/Landing";
@import "pages/marketing/LandingTVA";
@import "pages/marketing/LandingWeb3Theatre";
@import "pages/marketing/AiShowcases";
@import "pages/marketing/AiArtContest";
@import "pages/marketing/AiShowcasesTerms";

// Global
@import "pages/dashboard/DashboardPage";
@import "pages/dashboard/ServicePage";
@import "pages/dashboard/Login";
@import "pages/dashboard/ResetPassword";
@import "pages/dashboard/SignUp";
@import "pages/dashboard/VerifyEmail";
@import "pages/dashboard/Subscribe";
@import "pages/dashboard/Payment";
@import "pages/dashboard/Settings";
@import "pages/dashboard/Metrics";
@import "pages/dashboard/SelectOrg";
@import "pages/dashboard/AcceptProjectInvite";
@import "pages/dashboard/ComingSoon";
@import "pages/dashboard/NotFound";

// VideoServices
@import "pages/dashboard/video-services/VideoServiceMain";
@import "pages/dashboard/video-services/CreateVideo";
@import "pages/dashboard/video-services/Video";
@import "pages/dashboard/video-services/Stream";

// AiServices
@import "pages/dashboard/ai-services/AiServiceMain";
@import "pages/dashboard/ai-services/ModelExplorerPage";
@import "pages/dashboard/ai-services/ModelDeploymentDetailPage";
@import "pages/dashboard/ai-services/RagChatbotDetailPage";
@import "pages/dashboard/ai-services/StorageDetailsPage";

// Settings
@import "pages/dashboard/settings/TeamMembers";
@import "pages/dashboard/settings/Projects";
@import "pages/dashboard/settings/Profile";
@import "pages/dashboard/settings/Security";
@import "pages/dashboard/settings/Billing";
@import "pages/dashboard/settings/Quota";
@import "pages/dashboard/settings/Support";
@import "pages/dashboard/settings/Usage";

// Pages end

// Modals
@import "modals/CreateNotebookModal";
@import "modals/CreateDeploymentModal";
@import "modals/CreateCustomImageModal";
@import "modals/ManageGPUNodeModal";
@import "modals/CreateRAGChatbotModal";
@import "modals/SelectProjectModal";
@import "modals/DeleteModal";
@import "modals/InviteTeamMembersToProjectModal";
@import "modals/AutoRechargeModal";
@import "modals/LoginModal";
@import "modals/SignUpModal";
@import "modals/AddCreditModal";
@import "modals/ShowcaseTextToImageDiscoverModal";
@import "modals/StorageModal";
@import "modals/DepositTfuelModal";
@import "modals/CreateDocumentModal";

html {
  font-size: 62.5%;
  @include breakpoint("phone-wide") {
    font-size: 50%;
  }
}

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  font-size: 1.4rem;
  background: $color-background;
  //background-color: $color-background;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

.App {
  &--loading {
    height: 100vh;
    display: flex;
  }

  &--dashboard {
    min-width: 850px;
  }
}

a {
  &.red {
    color: $color-red;
  }
}
#root {
}

.color-green {
  color: $color-green;
}

.color-red {
  color: $color-red;
}

.text-optional {
  color: rgba($color-font-sub, 0.6);
  font-size: 14px;
}